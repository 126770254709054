.popup-wrap {
    width: 100%;
    height: 100%;
    display: none;
    position: absolute;
    top: 0px;
    left: 0px;
    content: "";
    background: rgba(0, 0, 0, 0.85);
}

.popup-box {
    width: 100%;
    height: 100%;
    padding: 70px;
    //transform: translate(-50%, -50%) scale(0.5);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 3px;
    background: #fff;
    text-align: center;
}

.popup-wrap__open {
    display: block;
}

.popup-box__green {
    background-color: $color-green;
}

.popup-box__blue {
    background-color: $color-blue;
}

.popup-box__red {
    background-color: $color-red;
}

.popup-box__yellow {
    background-color: $color-yellow;
}

.transform-in,
.transform-out {
    display: block;
    -webkit-transition: all ease 0.5s;
    transition: all ease 0.5s;
}

.transform-in {
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
}

.transform-out {
    -webkit-transform: translate(-50%, -50%) scale(0.5);
    transform: translate(-50%, -50%) scale(0.5);
}
