@import "variables";
@import "popupBox";

@font-face {
    font-family: "Akrobat Regular";
    font-style: normal;
    font-weight: normal;
    src:
        local("Akrobat Regular"),
        url("../../public/assets/Akrobat-Regular.woff") format("woff");
}

@font-face {
    font-family: "Akrobat Light";
    font-style: normal;
    font-weight: normal;
    src:
        local("Akrobat Light"),
        url("../../public/assets/Akrobat-Light.woff") format("woff");
}

@font-face {
    font-family: "Akrobat ExtraBold";
    font-style: normal;
    font-weight: normal;
    src:
        local("Akrobat ExtraBold"),
        url("../../public/assets/Akrobat-ExtraBold.woff") format("woff");
}

.btn {
    padding: $spacer-sm;
    font-size: $font-size-md;
    border: 1px solid;
    border-radius: $border-radius-xs;
    user-select: none;
    background-color: $color-blue;
    border-color: $color-blue;
    color: $color-white;
    cursor: pointer;
    appearance: none;
    box-sizing: border-box;

    &:hover {
        background-color: $color-blue-dark;
        border-color: $color-blue-dark;
    }
}

.btn-icon {
    height: 100%;
}

.btn-round {
    border-radius: 50%;
    width: 60px;
    height: 60px;
}

.btn-primary {
}

.icon {
}

.page {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.page-content {
    display: flex;
    flex-direction: column;
    padding: $spacer-lg;
    flex-grow: 1;
}

.section-title {
    font-family: $font-family-light;
    color: $color-grey-light;
    font-size: $font-size-h6;
}

.grid-item {
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    text-align: left;
    height: 120px;
    background-color: $color-grey-dark;
}

.grid-item-title {
    display: flex;
    align-items: center;
    margin: 0;
    padding: $spacer 0 0 $spacer;
    color: #fff;
}

.grid-item-icon {
    height: 30px;
    background-color: #fff;
    color: $color-grey-dark;
    border-radius: 50%;
    margin-right: $spacer;
}

.grid-item-content {
    padding: $spacer;
    color: $color-grey-light;
}

.grid-item-green {
    .grid-item-icon {
        background-color: $color-green;
        color: $color-grey-dark;
    }

    .grid-item-content {
        color: $color-green;
    }
}

.grid-item-red {
    .grid-item-icon {
        background-color: $color-red;
        color: $color-grey-dark;
    }

    .grid-item-content {
        color: $color-red;
    }
}

.grid-item-blue {
    .grid-item-icon {
        background-color: $color-blue;
        color: $color-grey-dark;
    }

    .grid-item-content {
        color: $color-blue;
    }
}

.grid-item-yellow {
    .grid-item-icon {
        background-color: $color-yellow;
        color: $color-grey-dark;
    }

    .grid-item-content {
        color: $color-yellow;
    }
}
