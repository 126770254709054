@import '../../styles/variables';

.settings {
    flex-grow: 1;
}

.settings-items {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.settingItem {
    flex: calc(50% - 20px);
    cursor: pointer;
}

.item-value {
    display: block;
    font-size: 2em;
}

.header {
    height: 100px;
    text-align: center;
    padding-top: 5px;
    background-color: $header-color;
    color: $header-text-color;
    font-family: $font-family-bold;
}

.btn-play {
    margin-top: $spacer-lg;
    width: 100%;
    height: 80px;
}