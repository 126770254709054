@import './styles/variables';
@import "styles/main";

body {
  margin: 0;
  height: 100%;
  width: 100%;
  overscroll-behavior-y: contain;
  background-color: $color-grey-darker;
  font-family: $font-family-regular;
}

.root {
  min-height: 100vh;
  color: white;
  
}

a {
  text-decoration: none;
}

a:hover {
  outline: none;
  text-decoration: none;
}

.circularProgressbar {
  display: block;
  position: relative;
  width: 200px;
  height: 200px;
}

.circularProgressbar-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}