@use "sass:math";

$bg-color: #212121;
$main-color: #E91E63;


$header-color: $main-color;
$header-text-color: $bg-color;

$font-family-light: 'Akrobat Light';
$font-family-regular: 'Akrobat Regular';
$font-family-bold: 'Akrobat ExtraBold';

// Font sizes
$font-size-base: 1rem; //16px
$font-size-h1: $font-size-base * 6; //Headline 1 (96px)
$font-size-h2: $font-size-base * 3.75; //Headline 2 (60px)
$font-size-h3: $font-size-base * 3; //Headline 3 (48px)
$font-size-h4: $font-size-base * 2.125; //Headline 4 (34px)
$font-size-h5: $font-size-base * 1.5; //Headline 5 (24px)
$font-size-h6: $font-size-base * 1.25; //Headline 6 (20px)

$font-size-xs: $font-size-base * 0.625; //10px
$font-size-sm: $font-size-base * 0.75; //12px
$font-size-md: $font-size-base * 0.875; //14px
$font-size-lg: $font-size-base * 1.125; //18px
$font-size-xl: $font-size-base * 1.25; //20px

// Font weight
$font-weight-semi-bold: 500;
$font-weight-bold: 600;

// Border
$border-radius-xs: 2px;
$border-radius-sm: 4px;
$border-radius: 6px;
$border-radius-md: 8px;
$border-radius-lg: 10px;

// Spacers
$spacer: 1rem !default;
$spacers: (
	0: 0,
	1: math.div($spacer, 4),
	2: math.div($spacer, 2),
	3: $spacer,
	4: $spacer * 1.5,
	5: $spacer * 3,
) !default;
$spacer-xs: map-get($spacers, 1); //4px
$spacer-sm: map-get($spacers, 2); //8px
$spacer: map-get($spacers, 3); //16px
$spacer-md: map-get($spacers, 4); //24px
$spacer-lg: map-get($spacers, 5); //48px

// Color
$color-white: #fff;
$color-black: #000;

$color-red: #f52f55;
$color-red-light: #ef9a9a;
$color-red-lighter: #ffebee;
$color-red-dark: #d32f2f;
$color-red-darker: #b71c1c;

$color-green: #5fe4a3;
$color-green-light: #a5d6a7;
$color-green-lighter: #00f153;
$color-green-dark: #43a047;
$color-green-darker: #2e7d32;

$color-grey: #3f3f3f;
$color-grey-light: #7f7e83;
$color-grey-lighter: #e2e2e2;
$color-grey-dark: #292929;
$color-grey-darker: #252525;

$color-amber: #ffc107;
$color-amber-light: #ffd54f;
$color-amber-lighter: #fff8e1;
$color-amber-dark: #ffa000;
$color-amber-darker: #ff6f00;

$color-orange: #f8b123;
$color-orange-light: #ffd0b3;
$color-orange-lighter: #fff3e0;
$color-orange-dark: #ec9f83;
$color-orange-darker: #b77056;

$color-blue: #6478f6;
$color-blue-light: #4fc3f7;
$color-blue-lighter: #b3e5fc;
$color-blue-dark: #0288d1;
$color-blue-darker: #01579b;

$color-cyan: #00bcd4;
$color-cyan-light: #4dd0e1;
$color-cyan-lighter: #b2ebf2;
$color-cyan-dark: #0097a7;
$color-cyan-darker: #006064;

$color-blue-grey: #607d8b;
$color-blue-grey-light: #90a4ae;
$color-blue-grey-lighter: #cfd8dc;
$color-blue-grey-dark: #455a64;
$color-blue-grey-darker: #263238;

$color-yellow: #f2ce62;
$color-yellow-light: #fffc57;
$color-yellow-lighter: #fffeaf;
$color-yellow-dark: #d3cf00;
$color-yellow-darker: #a7a400;

$color-purple: #9900ff;
$color-purple-light: #ae36fd;
$color-purple-lighter: #d493ff;
$color-purple-dark: #7a00cc;
$color-purple-darker: #54008b;

// Shadows
@mixin box_shadow($level, $color: $color-black) {
	@if $level == 1 {
		box-shadow: 0 1px 3px rgba($color, 0.05), 0 1px 6px rgba($color, 0.08);
	} @else if $level == 2 {
		box-shadow: 0 3px 6px rgba($color, 0.16), 0 3px 6px rgba($color, 0.23);
	} @else if $level == 3 {
		box-shadow: 0 10px 20px rgba($color, 0.19), 0 6px 6px rgba($color, 0.23);
	} @else if $level == 4 {
		box-shadow: 0 14px 28px rgba($color, 0.25), 0 10px 10px rgba($color, 0.22);
	} @else if $level == 5 {
		box-shadow: 0 19px 38px rgba($color, 0.3), 0 15px 12px rgba($color, 0.22);
	}
}

$standard-curve: cubic-bezier(0.4, 0, 0.2, 1);
$deceleration-curve: cubic-bezier(0, 0, 0.2, 1);
$acceleration-curve: cubic-bezier(0.4, 0, 1, 1);
$sharp-curve: cubic-bezier(0.4, 0, 0.2, 1);