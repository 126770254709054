@import '../../styles/variables';

.settings {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.settingItem {
    flex: calc(50% - 20px);
}

.timer {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    margin-top: $spacer-lg;
}

.btn-action {
    padding: $spacer;
}

.footer {
    display: flex;
    justify-content: center;
}